.authCheckbox {
    position: unset!important;
    margin-top: 0!important;
    margin-left: 0!important;
}

.table-min-height {
    min-height: 700px;
}
.modale {
    position: absolute;
    top: -90%;
}